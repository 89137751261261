import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import DataTable from 'lib/DataTable';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { healthCheck, importAll, seedDatabase, pullLatestCSVs } from 'services/AdminService';
import ServiceHealthGrid from './ServiceHealthGrid';
import { useNavigate } from 'react-router-dom';
import { APP_CONFIG } from 'config/config';
import ManualImport from './ManualImport';

export default function Database() {
    const [counts, setCounts] = useState(undefined);
    const [importInProgress, setImportInProgress] = useState(false);
    const confirm = useConfirm();
    const navigate = useNavigate();
    useEffect(() => {
        healthCheck({}, (res) => {
            setCounts(res);
        });
    }, []);

    const importAllClicked = () => {
        confirm({
            description: 'Are you sure you want to import all tournaments from source data?'
        })
            .then(() => {
                importAll({}, (res) => {
                    // if (res.status)
                    console.log(res);
                });
            })
            .catch(() => {
                /* ... */
            });
    };

    const seed = () => {
        confirm({
            description: 'Are you sure you want to seed the database?'
        })
            .then(() => {
                seedDatabase({}, (res) => {
                    // if (res.status)
                    console.log(res);
                });
            })
            .catch(() => {
                /* ... */
            });
    };

    const importScoreReports = () => {
        fetch(`${APP_CONFIG.apiUrl}/v1/import-all-score-report`, { method: 'POST' });
    };

    const pullLatestCSVsClicked = () => {
        confirm({
            description: 'Are you sure you want to pull the latest csvs?'
        })
            .then(() => {
                pullLatestCSVs({}, (res) => {
                    // if (res.status)
                    console.log(res);
                });
            })
            .catch(() => {
                /* ... */
            });
    };

    return (
        <Box>
            <h3>Data</h3>
            <Box sx={{ display: 'flex', '> Button': { mr: 2 } }}>
                <Button variant="contained" onClick={() => navigate('/admin/merge-teams')}>
                    Merge teams
                </Button>
                <Button variant="contained" disabled onClick={() => seed()}>
                    Seed
                </Button>
                <Button variant="contained" disabled onClick={() => importAllClicked()}>
                    Import all
                </Button>
                <Button variant="contained" disabled onClick={importScoreReports}>
                    Import all scorereports
                </Button>
                {/* <Button variant="contained" onClick={() => pullLatestCSVsClicked()}>
                    Pull latest csvs
                </Button> */}
            </Box>

            <h3>Manual import</h3>
            <ManualImport />

            <h3>Health</h3>
            <Table sx={{ width: '300px' }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>Table</b>
                        </TableCell>
                        <TableCell>
                            <b>Count</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {counts &&
                        Object.keys(counts).map((key) => (
                            <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>{counts[key]}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            <ServiceHealthGrid />
        </Box>
    );
}
