import { APP_CONFIG } from 'config/config';

const baseUrl = `${APP_CONFIG.apiUrl}/v1/roles`;

export function listUsers(request, callback) {
    fetch(`${baseUrl}/users`)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function getUser(request, callback) {
    fetch(`${baseUrl}/users/${request.id}`)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function addRole(request, callback) {
    fetch(`${baseUrl}/add-role`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }).then((response) => callback(response));
}

export function removeRole(request, callback) {
    fetch(`${baseUrl}/remove-role`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }).then((response) => callback(response));
}

export function sessionInfo(request, callback) {
    fetch(`${baseUrl}/sessionInfo`)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function seedDatabase(request, callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/seed`, { method: 'POST' }).then((response) =>
        callback(response)
    );
}

export function importAll(request, callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/import-all`, { method: 'POST' }).then((response) =>
        callback(response)
    );
}

export function healthCheck(request, callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/health-check`, { method: 'GET' })
        .then((data) => data.json())
        .then((response) => callback(response));
}

export function pullLatestCSVs(request, callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/pull-latest`, { method: 'POST' }).then((response) =>
        callback(response)
    );
}

export function getVideoSources(callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/videos/sources`, { method: 'GET' })
        .then((data) => data.json())
        .then((response) => callback(response));
}

export function postNewVideoSource(request, callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/videos/sources`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    })
        .then((data) => data.json())
        .then((response) => callback(response));
}

export function putVideoSourceUpdate(request, callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/videos/sources`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    })
        .then((data) => data.json())
        .then((response) => callback(response));
}

export function mergeTeamRecords(request, callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/merge-teams`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }).then((response) => callback(response));
}

export function liveImport(request, callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/ingest`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }).then((response) => callback(response));
}
